import Bianca from "../Assets/ContactAssets/Bianca.jpeg";
import Farnaz from "../Assets/ContactAssets/Farnaz.jpeg";
// import Alice from "../Assets/ContactAssets/Alice.jpeg";
// import Eunice from "../Assets/ContactAssets/Eunice.jpeg";
// import Stephen from "../Assets/ContactAssets/Stephen.jpeg";
// import Michka from "../Assets/ContactAssets/Michka.jpeg";
// import CarolCurtis from "../Assets/ContactAssets/CarolCurtis.jpeg";
import CarolCurtis from "../Assets/ContactAssets/CarolCurtis.jpg";
// import ChristinaAnnunziata from "../Assets/ContactAssets/ChristinaAnnunziata.jpeg";
// import DouglasHurst from "../Assets/ContactAssets/DouglasHurst.jpeg";
// import JoanneElena from "../Assets/ContactAssets/JoanneElena.jpeg";
// import KristenCincotta from "../Assets/ContactAssets/KristenCincotta.jpeg";
// import LucasDeBreed from "../Assets/ContactAssets/LucasDeBreed.jpeg";
// import LynneElmore from "../Assets/ContactAssets/LynneElmore.png";
// import JoeCotter from "../Assets/ContactAssets/JoeCotter.jpeg";
// import NateMachtGreenberg from "../Assets/ContactAssets/NateMachtGreenberg.jpeg";

const contacts = [
	// {
	// 	firstName: "Alice",
	// 	middleName: "Lin",
	// 	lastName: "Pomponio",
	// 	picture: Alice,
	// 	title: "Managing Director",
	// 	company: "BrightEdge",
	// 	companyLogo: "",
	// 	email: "alice.pomponio@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/alicelinpomponio/",
	// },
	{
		firstName: "Farnaz",
		middleName: "",
		lastName: "Bakhshi",
		picture: Farnaz,
		title: "Director, Innovation",
		company: "BrightEdge",
		companyLogo: "",
		email: "farnaz.bakhshi@cancer.org",
		expertise: ["Powerpoint", "Science", "Business Development"],
		linkedIn: "https://www.linkedin.com/in/farnaz-bakhshi1/",
	},
	{
		firstName: "Bianca",
		middleName: "",
		lastName: "Scimemi",
		picture: Bianca,
		title: "Entrepreneurship Program Manager",
		company: "BrightEdge",
		companyLogo: "",
		email: "bianca.scimemi@cancer.org",
		expertise: ["Powerpoint", "Science", "Business Development"],
		linkedIn: "https://www.linkedin.com/in/bianca-scimemi-8a740613a/",
	},
	// {
	// 	firstName: "Michka",
	// 	middleName: "",
	// 	lastName: "Sharpe",
	// 	picture: Michka,
	// 	title: "Associate",
	// 	company: "BrightEdge",
	// 	companyLogo: "",
	// 	email: "michka.sharpe@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/michka-sharpe-33ba8a12b/",
	// },
	// {
	// 	firstName: "Eunice",
	// 	middleName: "",
	// 	lastName: "Chapon",
	// 	picture: Eunice,
	// 	title: "Director, Operations",
	// 	company: "BrightEdge",
	// 	companyLogo: "",
	// 	email: "bright@edge.com",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/eunicechapon/",
	// },
	// {
	// 	firstName: "Stephen",
	// 	middleName: "",
	// 	lastName: "Curtis",
	// 	picture: Stephen,
	// 	title: "Director, New Ventures",
	// 	company: "BrightEdge",
	// 	companyLogo: "",
	// 	email: "stephen.curtis@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/stephenjcurtis/",
	// },
	{
		firstName: "Carol",
		middleName: "",
		lastName: "Curtis",
		picture: CarolCurtis,
		title: "Entreprenuer | Scientist | Advisor",
		company: "BrightEdge Consultant",
		companyLogo: "",
		email: "carol@csquareconsulting.com",
		expertise: ["Powerpoint", "Science", "Business Development"],
		linkedIn: "https://www.linkedin.com/in/carol-curtis-phd/",
	},
	// {
	// 	firstName: "Christina",
	// 	middleName: "",
	// 	lastName: "Annunziata",
	// 	picture: ChristinaAnnunziata,
	// 	title: "Senior Vice President",
	// 	company: "American Cancer Society",
	// 	companyLogo: "",
	// 	email: "christina.annunziata@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/christina-annunziata-09364541/",
	// },
	// {
	// 	firstName: "Douglas",
	// 	middleName: "",
	// 	lastName: "Hurst",
	// 	picture: DouglasHurst,
	// 	title: "Scientific Director, Biochemistry & Immunology of Cancer",
	// 	company: "American Cancer Society",
	// 	companyLogo: "",
	// 	email: "doug.hurst@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/douglas-hurst-8516a210/",
	// },
	// {
	// 	firstName: "Joanne",
	// 	middleName: "",
	// 	lastName: "Elena",
	// 	picture: JoanneElena,
	// 	title: "Scientific Director",
	// 	company: "American Cancer Society",
	// 	companyLogo: "",
	// 	email: "joanne.elena@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/joanne-elena-72370628a/",
	// },
	// {
	// 	firstName: "Joe",
	// 	middleName: "",
	// 	lastName: "Cotter",
	// 	picture: JoeCotter,
	// 	title: "Engagement, Communications, Analytics",
	// 	company: "American Cancer Society",
	// 	companyLogo: "",
	// 	email: "joseph.cotter@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/joecotter10/",
	// },
	// // {
	// // 	firstName: "Kristen",
	// // 	middleName: "",
	// // 	lastName: "Cincotta",
	// // 	picture: KristenCincotta,
	// // 	title: "Scientific Communications Project Manager",
	// // 	company: "ICF",
	// // 	companyLogo: "",
	// // 	email: "KristenCincotta.com ",
	// // 	expertise: ["Powerpoint", "Science", "Business Development"],
	// // 	linkedIn: "https://www.linkedin.com/in/kristen-cincotta-58671115/",
	// // },
	// {
	// 	firstName: "Lucas",
	// 	middleName: "",
	// 	lastName: "de Breed",
	// 	picture: LucasDeBreed,
	// 	title: "Director",
	// 	company: "BrightEdge",
	// 	companyLogo: "",
	// 	email: "lucas.debreed@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/lucasdebreed/",
	// },
	// {
	// 	firstName: "Lynne",
	// 	middleName: "",
	// 	lastName: "Elmore",
	// 	picture: LynneElmore,
	// 	title:
	// 		"Senior Scientific Director, Cell Biology and Preclinical Cancer Research",
	// 	company: "American Cancer Society",
	// 	companyLogo: "",
	// 	email: "lynne.elmore@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/lynne-elmore/",
	// },
	// {
	// 	firstName: "Nate",
	// 	middleName: "",
	// 	lastName: "Macht-Greenberg",
	// 	picture: NateMachtGreenberg,
	// 	title: "Investment and Innovation Analyst",
	// 	company: "BrightEdge",
	// 	companyLogo: "",
	// 	email: "nate.macht-greenberg@cancer.org",
	// 	expertise: ["Powerpoint", "Science", "Business Development"],
	// 	linkedIn: "https://www.linkedin.com/in/natemacht-greenberg/",
	// },
];

export default contacts;
