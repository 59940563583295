import * as React from "react";
import Typography from '@mui/joy/Typography';
import { documents, color } from '../../../spec';
import { styled } from '@mui/joy/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DocumentCard from './DocumentCard';
import Hidden from '@mui/material/Hidden';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(3),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));

export default function Documents() {

	return (
		<Box sx={{ flexGrow: 1, p: 3 }} >
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Item style={{height: "100%"}} >			
					<Hidden mdDown>
							<Typography align="center" variant="h1" gutterBottom style={{fontSize: '3.5vw', color: '#303030'}}>
								<b>Resources</b>
							</Typography>
						</Hidden>
						<Hidden mdUp>
							<Typography align="center" variant="h1" gutterBottom style={{fontSize: '40px', color: '#303030'}}>
								<b>Resources</b>
							</Typography>
						</Hidden>
					</Item>
				</Grid>
					{documents.map((e) => <Grid item xs={12} sm={6} md={4} lg={3}><DocumentCard document={e}/></Grid>)}
			</Grid>
		</Box>
	)
}
