import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/material/Grid';

export default function ContactCard(props) {

  return (
    <Grid container 
    alignItems="center"
    justifyContent="center"
    spacing={3} style={{padding: 'none'}}>
       <Grid item xs={12} lg={6} style={{padding: 'none'}}>
       <Card>
      <AspectRatio 
        ratio = "5/6" 
        // minHeight="50px" 
        // maxHeight="100px" 
        // objectFit="contain"
        objectFit="cover"
        >
        <img
          src= {props.contact.picture}
          srcSet= {props.contact.picture}
          loading="lazy"
          alt="contact"
        />
      </AspectRatio>
    </Card>
       </Grid>
       <Grid item xs={12} lg={6}>
						<Typography align="center" variant="h5" gutterBottom >
                {props.contact.text}
						</Typography>
            <Typography align="center" variant="h5" gutterBottom >
                {props.contact.textTwo}
						</Typography>
       </Grid>
    </Grid>
  );
}