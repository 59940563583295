import * as React from 'react';
import TwoSidedLayout from './TwoSidedLayout';
import LandingPageNavbar from './LandingPageNavbar';
import Offerings from './Offerings';
// import Sponsorships from './Sponsorships';
import UseCases from './UseCases'
import Footer from './LandingPageFooter';

export default function LandingPage() {

  return (
    <>
    <LandingPageNavbar/>
    <TwoSidedLayout />
    <Offerings/>
    <UseCases/>
    {/* <Sponsorships/> */}
    <Footer/>
    </>
  );
}