import React from 'react';
import FullSize from './FullSizeDashboardLayout';
import MobileSize from './MobileDashboardLayout';
import Hidden from '@mui/material/Hidden';

function DashboardLayout(props) {

  return (
    <>
    <Hidden mdDown>
        <FullSize children={props.children}/>
    </Hidden>
     <Hidden mdUp>
        <MobileSize children={props.children}/>
    </Hidden>
    </>
  );
}
export default DashboardLayout;