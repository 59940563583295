import * as React from 'react';
import 'moment-timezone';
import moment from 'moment'
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardCover from '@mui/joy/CardCover';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import { color, companyName } from '../../../spec';
import { Container } from '@mui/material';
import ContactCard from './AgendaContactCard';
import Button from '@mui/joy/Button';
import ArrowIcon from '@mui/icons-material/ArrowOutward';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

// https://add-to-calendar-button.com/configuration

export default function BasicCard(props) {
    const agenda = props.agenda
    
  return (
    <Grid container 
    alignItems="center"
    justifyContent="center"
    spacing={3} style={{padding: 'none'}}>
       <Hidden mdDown>
       <Grid item xs={3} style={{padding: 'none'}}>
        <Card  style={{ border: 'none', margin: 'none', padding: 'none'}} >
          <CardCover>
            <video style={{ borderRadius: '20px'}}
              autoPlay
              loop
              muted
              poster={agenda.content.picture}
            >
              <source
                src= {agenda.content.video}
                type="video/mp4"
              />
            </video>
          </CardCover>
          <CardContent>
            <Typography
              level="body-lg"
              fontWeight="lg"
              textColor="#fff"
              // fontSize='200%'
              mt={{ xs: 12, sm: 24 }}
            >
              <b>{agenda.content.topic}</b>
            </Typography>
          </CardContent>
        </Card>
       </Grid>
       </Hidden>
       <Hidden mdDown>
       <Grid item xs={12} md={6}>
						<Typography align="center" variant="h1" gutterBottom 
            style={{fontSize: '2vw', color: 'black'}}
            >
							<b>{agenda.content.title}</b>
						</Typography>
            <Container>
            <Typography align="center" variant="h2" gutterBottom  style={{fontSize: '1.2vw', color: 'black'}} >
                {`${moment(`${agenda.time.startDate} ${agenda.time.startTime}`).format('MMMM Do [from] h:mm a')} ${moment(`${agenda.time.startDate} ${agenda.time.endTime}`).format('[to] h:mm a')} ${agenda.time.timeZone}`} 
						</Typography>
            <Typography align="center" variant="h2" gutterBottom  style={{fontSize: '1.2vw', color: 'black'}} >
						</Typography>

						<Typography align="center" variant="h5" gutterBottom >
                {agenda.content.description}
						</Typography>
            <br/>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
            {/* <ButtonGroup > */}
            <Button 
              // style={{backgroundColor: color, color: 'white'}}
              disabled={agenda.access.liveLink ? false : true}
              onClick={() => window.open(`${agenda.access.liveLink}`, '_blank')}
              sx={{
                backgroundColor: color,
                "&:hover": {
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid grey',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
              }}
              startDecorator={<ArrowIcon/>}>
                Go to Presentation
            </Button>
                        <Button 
              // style={{backgroundColor: color, color: 'white'}}
              disabled={agenda.content.slides ? false : true}
              onClick={() => window.open(`${agenda.access.liveLink}`, '_blank')}
              sx={{
                backgroundColor: color,
                "&:hover": {
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid grey',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
              }}
              startDecorator={<SlideshowIcon/>}>
                View Slides
            </Button>
        <AddToCalendarButton
            // hideIconButton
            styleLight={`--btn-background: ${color}; --btn-text: #fff;`}
            size ='3'
            trigger='click'
            name={`${companyName} - ${agenda.content.title}`}
            options={['Google','Outlook.com','Microsoft 365','Microsoft Teams','Apple','iCal','Yahoo']}
            location="World Wide Web"
            startDate={agenda.time.startDate}
            endDate={agenda.time.endDate}
            startTime={agenda.time.startTime}
            endTime={agenda.time.endTime}
            timeZone="America/New_York"
          ></AddToCalendarButton>
        {/* </ButtonGroup> */}

        </Box>
            </Container>
          
       </Grid>
       </Hidden>
       <Hidden mdUp>
       <Grid item xs={12} md={6}>
						<Typography align="center" variant="h1" gutterBottom 
            style={{fontSize: '4vw', color: 'black'}}

            >
							<b>{agenda.content.title}</b>
						</Typography>
            <Container>
            <Typography align="center" variant="h2" gutterBottom  style={{ color: 'black'}} >
            {`${moment(`${agenda.time.startDate} ${agenda.time.startTime}`).format('MMMM Do [from] h:mm a')} ${moment(`${agenda.time.startDate} ${agenda.time.endTime}`).format('[to] h:mm a')} ${agenda.time.timeZone}`} 
						</Typography>
						<Typography align="center" variant="h5" gutterBottom >
                {agenda.content.description}
						</Typography>
            </Container>
            <br/>
            <Button 
              // style={{backgroundColor: color, color: 'white'}}
              disabled={agenda.access.liveLink ? false : true}
              onClick={() => window.open(`${agenda.access.liveLink}`, '_blank')}
              sx={{
                backgroundColor: color,
                "&:hover": {
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid grey',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
              }}
              startDecorator={<ArrowIcon/>}>
                Go to Presentation
            </Button>
            <Button 
              // style={{backgroundColor: color, color: 'white'}}
              disabled={agenda.content.slides ? false : true}
              onClick={() => window.open(`${agenda.access.liveLink}`, '_blank')}
              sx={{
                backgroundColor: color,
                "&:hover": {
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid grey',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
              }}
              startDecorator={<SlideshowIcon/>}>
                View Slides
            </Button>
            <br/><br/>
            {/* <Button 
              style={{backgroundColor: color}}
              onClick={() => window.open(`${props.agenda.liveLink}`, '_blank')}
              startDecorator={<EventIcon/>}>
                Add to Calendar
        </Button> */}
        						<Typography align="center" variant="h5" gutterBottom >

        <AddToCalendarButton
            // hideIconButton
            styleLight={`--btn-background: ${color}; --btn-text: #fff;`}
            size ='3'
            trigger='click'
            name={`${companyName} - ${agenda.content.title}`}
            options={['Google','Outlook.com','Microsoft 365','Microsoft Teams','Apple','iCal','Yahoo']}
            location="World Wide Web"
            startDate={agenda.time.startDate}
            endDate={agenda.time.endDate}
            startTime={agenda.time.startTime}
            endTime={agenda.time.endTime}
            timeZone="America/New_York"
          ></AddToCalendarButton>
						</Typography>
       </Grid>
       </Hidden>
       <Grid item xs={12} md={3}>
       <ContactCard presenter = {agenda.presenter}/>
       </Grid>
    </Grid>
  )
}