import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Container from '@mui/joy/Container';
import { typographyClasses } from '@mui/joy/Typography';
import Hero from '../../Assets/NameAndLogo.png';
import { Link } from "react-router-dom";
import {color } from '../../spec';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import Image from '../../Assets/LandingPageAssets/UseCases.png';
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor'; // Town Hall
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'; // Town Hall
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LightbulbIcon from '@mui/icons-material/Lightbulb'; // Education
import MonitorIcon from '@mui/icons-material/Monitor'; // Webinar
import ViewComfyIcon from '@mui/icons-material/ViewComfy'; // Poster Hall

const cardContent = [
    {
    title: 'Town Hall',
    subTitle: 'A definitive ecosystem for your department',
    icon: <CameraIndoorIcon fontSize='large'/>,
    }, {
      title: 'Poster Hall',
      subTitle: 'Showcase and elevate your science',
      icon: <ViewComfyIcon fontSize='large' />,
    }, {
      title: 'Webinars',
      subTitle: 'Give your clients a boutique digital experience',
      icon: <MonitorIcon fontSize='large' />,
    }, {
    title: 'Education',
    subTitle: 'Provide a bespoke program for your scientists',
    icon: <LightbulbIcon fontSize='large' />,
    },
    
  ]

export default function TwoSidedLayout({
  children,
  reversed,
}: React.PropsWithChildren<{ reversed?: boolean }>) {
  return (
    <Container>
    <Container
      sx={(theme) => ({
        position: 'relative',
        minHeight: '60vh',
        display: 'flex',
        flexDirection: reversed ? 'column-reverse' : 'column',
        alignItems: 'center',
        py: 10,
        gap: 4,
        [theme.breakpoints.up(834)]: {
          flexDirection: 'row',
          gap: 6,
        },
        [theme.breakpoints.up(1199)]: {
          gap: 12,
        },
      })}
    >
              <AspectRatio
        ratio={1}
        variant="outlined"
        maxHeight={400}
        height={'auto'}
        sx={(theme) => ({
          minWidth: 200,
          alignSelf: 'stretch',
          [theme.breakpoints.up(834)]: {
            alignSelf: 'initial',
            flexGrow: 1,
            '--AspectRatio-maxHeight': '400px',
            '--AspectRatio-minHeight': '200px',
          },
          borderRadius: 'sm',
          bgcolor: 'background.level2',
          flexBasis: '30%',
        })}
      >
        <img
          src={Image}
          alt=""
        />
      </AspectRatio>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
          maxWidth: '50ch',
          textAlign: 'center',
          flexShrink: 999,
          [theme.breakpoints.up(834)]: {
            minWidth: 420,
            alignItems: 'flex-start',
            textAlign: 'initial',
          },
          [`& .${typographyClasses.root}`]: {
            textWrap: 'balance',
          },
        })}
      >
    <Grid container spacing={3}>
        {cardContent.map((card)=> {
            return(
                <Grid item xs={12} md={6}>
                    <Typography 
                    style={{padding: '10px', color}}
                    fontSize="clamp(0.875rem, 1.3636rem + 0.4818vw, 6rem)"
                    align='center' level="title-lg">{card.icon} {card.title}
                    </Typography>
                    <Typography align='center' level="title-lg">{card.subTitle}</Typography>
                </Grid>
            )
        })}
    </Grid>
      </Box>
    </Container>
    </Container>
  );
}