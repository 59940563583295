import PerkinElmer from "../Assets/SponsorAssets/PerkinElmer.jpeg";
import Agilent from "../Assets/SponsorAssets/Agilent.gif";
import Bruker from "../Assets/SponsorAssets/Bruker.jpeg";
import Danaher from "../Assets/SponsorAssets/Danaher.webp";
import RevvityWhite from "../Assets/SponsorAssets/RevvityWhite.jpeg";
import Thermo from "../Assets/SponsorAssets/Thermo.jpeg";

const sponsors = [
	{
		name: "PerkinElmer",
		logo: PerkinElmer,
		description:
			"Together, we have partnered with laboratories around the world to enable and accelerate science for over 85 years. Driven by innovation, dedication, and an unwavering commitment to our customers, the global community, and our teams, our central aim remains focused on creating a positive impact.",
		website: "https://www.perkinelmer.com/",
		relatedPresentations: [],
		relatedContacts: [],
		expertise: [],
		products: [],
		services: [],
	},
	{
		name: "Revvity",
		logo: RevvityWhite,
		description:
			"When it comes to health and lives, accuracy and urgency are essential. We’re here to deliver solutions that power leading precision and performance, as rapidly as possible. To diagnose diseases earlier. To develop new treatments and cures sooner. To forge new pathways, faster. And to do it all with the highest level of quality",
		website: "https://www.thermofisher.com/us/en/home.html",
		relatedPresentations: [],
		relatedContacts: [],
		expertise: [],
		products: [],
		services: [],
	},
	{
		name: "Agilent",
		logo: Agilent,
		description:
			"Agilent supports scientists in 110 countries in cutting-edge life science research; patient diagnostics; and testing required to ensure the safety of water, food and pharmaceuticals. Our advanced instruments, software, consumables, and services enable our customers to produce the most accurate and reliable results as well as optimal scientific, economic, and operational outcomes.",
		website: "https://www.agilent.com/",
		relatedPresentations: [],
		relatedContacts: [],
		expertise: [],
		products: [],
		services: [],
	},
	{
		name: "Thermo Fisher Scientific",
		logo: Thermo,
		description:
			"BYOB (Bring Your Own Biotech): Lab Incubators Are Ready for Your Ideas: Biotech incubators like BioLabs at Pegasus Park will serve as much-needed infrastructure for the next big wave of discovery, in cooperation with key innovation technology partners like Thermo Fisher Scientific, while emerging startups lead the way.",
		website: "https://www.thermofisher.com/us/en/home.html",
		relatedPresentations: [],
		relatedContacts: [],
		expertise: [],
		products: [],
		services: [],
	},
	{
		name: "Danaher",
		logo: Danaher,
		description:
			"We’re Danaher - a leading global life sciences and diagnostics innovator, committed to accelerating the power of science and technology to improve human health.",
		website: "https://www.danaher.com/",
		relatedPresentations: [],
		relatedContacts: [],
		expertise: [],
		products: [],
		services: [],
	},
	{
		name: "Bruker",
		logo: Bruker,
		description:
			"Differentiated High Value Life Science Research and Diagnostics Solutions - We are a developer, manufacturer and distributor of high-performance scientific instruments and analytical and diagnostic solutions that enable our customers to explore life and materials at microscopic, molecular and cellular levels.",
		website: "https://www.bruker.com/en.html",
		relatedPresentations: [],
		relatedContacts: [],
		expertise: [],
		products: [],
		services: [],
	},
];

export default sponsors;
