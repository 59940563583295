import React from 'react';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@mui/material/Drawer';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/joy/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { 
    signOut } from 'aws-amplify/auth';
import {color, routes } from '../spec';

import LogoutIcon from '@mui/icons-material/Logout';
import LobbyIcon from '@mui/icons-material/Dashboard'; // Lobby
import AgendaIcon from '@mui/icons-material/Event'; // Agenda
import SponsorsIcon from '@mui/icons-material/RocketLaunch'; // Sponsors
import ContactsIcon from '@mui/icons-material/AccountBox'; // Contacts
import DocumentsIcon from '@mui/icons-material/Article'; // Documents
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'; // Mentors
import EngineeringIcon from '@mui/icons-material/Engineering'; // Entrepreneurs


const useStyles = makeStyles((theme) => ({
  menuIcon:{
    marginRight: '3vw'
  }
  // root:
  //   {
  //     backgroundColor: theme.palette.primary.main,
  //   },
  //   menuButton: {
  //     backgroundColor: theme.palette.primary.main,
  //     marginRight: theme.spacing(1),
  //     width: 48,
  //     height: 48,
  //     marginLeft: 36,
  //   },

}));

function ResponsiveAppBar(props) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  const classes = useStyles();

  

  const toggleDrawer = (inOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(inOpen);
  };

  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const handleNavigation = (link) => {
    navigate(link)
  }

  return (
    <>
    <AppBar 
    style={{backgroundColor:color}}
    position="fixed" 
    // color="transparent" 
    elevation={0}>
      <Container maxWidth="false">
        <Toolbar disableGutters>
        <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
            style={{color:'white'}}
          >
            <MenuIcon/>
          </IconButton>
        </Toolbar>
      </Container>
      <Drawer open={open} onClose={toggleDrawer(false)} size='sm'>
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{padding: '2vw'}}
        >
          <h3 style= {{ textAlign: 'center'}}>BrightEdge Entrepreneurs</h3>
          <List >
          {routes.lobby ?
            <ListItem key={'lobby'} onClick={()=>handleNavigation('/lobby')}>
            <ListItemButton className={classes.menuButton} >
                <LobbyIcon className={classes.menuIcon}/>
                  Home
              </ListItemButton>
            </ListItem>
            : null }
            {routes.program ?
            <ListItem key={'program'} onClick={()=>handleNavigation('/program')}>
            <ListItemButton>
                <AgendaIcon className={classes.menuIcon}/>
                  Program
              </ListItemButton>
            </ListItem>
            : null }
            {routes.sponsors ?
            <ListItem key={'sponsors'} onClick={()=>handleNavigation('/sponsors')}>
            <ListItemButton>
                <SponsorsIcon className={classes.menuIcon}/>
                  Sponsors
              </ListItemButton>
            </ListItem>
            : null }
            {routes.contacts ?
            <ListItem key={'contacts'} onClick={()=>handleNavigation('/contacts')}>
            <ListItemButton>
                <ContactsIcon className={classes.menuIcon}/>
                  Contacts
              </ListItemButton>
            </ListItem>
            : null }
            {routes.mentors ?
            <ListItem key={'mentors'} onClick={()=>handleNavigation('/mentors')}>
            <ListItemButton>
                <SupervisorAccountIcon className={classes.menuIcon}/>
                  Mentors
              </ListItemButton>
            </ListItem>
            : null }
            {routes.entrepreneurs ?
            <ListItem key={'entrepreneurs'} onClick={()=>handleNavigation('/entrepreneurs')}>
            <ListItemButton>
                <EngineeringIcon className={classes.menuIcon}/>
                  Entrepreneurs
              </ListItemButton>
            </ListItem>
            : null }
            {routes.resources ?
            <ListItem key={'resources'} onClick={()=>handleNavigation('/documents')}>
            <ListItemButton>
                <DocumentsIcon className={classes.menuIcon}/>
                  Resources
              </ListItemButton>
            </ListItem>
              : null }
          </List>
          <Divider />
          <List>
            <ListItem key="Logout" onClick={()=>handleSignOut()}>
                <ListItemButton><LogoutIcon className={classes.menuIcon}/>Logout</ListItemButton>
              </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
    <br/><br/>
    {props.children}
    </>
  );
}
export default ResponsiveAppBar;