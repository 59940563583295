import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from "@mui/joy/Box";
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Chip from '@mui/joy/Chip';
// import Add from '@mui/icons-material/Add';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { color } from '../../../spec';
import DescriptionIcon from '@mui/icons-material/Description';

// {
//   title: "List of CROs",
//   subTitle: "Integrated drug discovery and virtual biotechs",
//   picture: Logo,
//   fileType: "png",
//   fileLocation: "https:google.com",
//   fileSize: "1.25 MB",
// },

export default function DocumentCard(props) {

  return (
    <Card style={{height: '100%'}}>
      <AspectRatio ratio="2">
        <img
          src= {props.document.picture}
          srcSet= {props.document.picture}
          loading="lazy"
          alt="picture"
          style={{borderRadius: '5px 5px 0px 0px'}}
        />
        </AspectRatio>
      <CardContent >
        {/* <Typography level="h2">{`${props.document.firstName} ${props.document.lastName}`}</Typography> */}
         <Typography level="body-lg">{props.document.title}</Typography>
          <Typography level="h4">{props.document.subTitle}</Typography>
          <br/>
        {/* <Typography level="body-sm">Expertise:</Typography> */}
        <br/>
        {/* {props.document.expertise.map((e)=> <Chip color='primary'>{e}</Chip>)} */}
        <br/>
      </CardContent>
      <CardOverflow variant="soft">
      <Button 
        style={{backgroundColor: color}}
        onClick={() => window.open(`${props.document.fileLocation}`, '_blank')}
        startDecorator={<DescriptionIcon/>}>Access File</Button>
      <br/>
<Divider inset="context" />
<CardContent orientation="horizontal">
  <Typography level="body-xs">File Size: {props.document.fileSize}</Typography>
  <Divider orientation="vertical" />
  <Typography level="body-xs">File Type: {props.document.fileType}</Typography>
</CardContent>
</CardOverflow>
    </Card>
  );
}