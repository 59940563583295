import * as React from "react";
import Typography from '@mui/joy/Typography';
import {sponsors } from '../../../spec';
import { styled } from '@mui/joy/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SponsorCard from './SponsorCard';
import Hidden from '@mui/material/Hidden';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(3),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));

export default function Documents() {

	return (
		<Box sx={{ flexGrow: 1, p: 3 }} >
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Item style={{height: "100%"}} >			
					<Hidden mdDown>
							<Typography align="center" variant="h1" gutterBottom style={{fontSize: '3.5vw', color: '#303030'}}>
								<b>Sponsors</b>
							</Typography>
						</Hidden>
						<Hidden mdUp>
							<Typography align="center" variant="h1" gutterBottom style={{fontSize: '40px', color: '#303030'}}>
								<b>Sponsors</b>
							</Typography>
						</Hidden>
					</Item>
				</Grid>
					{sponsors.map((e) => <Grid item xs={12} sm={12} md={6} lg={4}>
						{/* <Item style={{height: '100%'}}> */}
							<SponsorCard 
								sponsor={e}/>
							{/* </Item> */}
					</Grid>)}
			</Grid>
		</Box>
	)
}
