import React, { useState } from 'react';
import Box from "@mui/joy/Box";
import Typography from '@mui/joy/Typography';
import Grid from '@mui/material/Grid';
import EntrepreneurCard from "./EntrepreneurCard";
import { entrepreneurs } from '../../../spec';
import { styled } from '@mui/joy/styles';
import Paper from '@mui/material/Paper';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import { color } from '../../../spec'

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(3),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));


export default function Mentors() {

	return (
    <Box sx={{ flexGrow: 1, p: 3 }} >
       <Grid container spacing={3} >
	   		<Grid item xs={12}>
				<Item style={{height: "100%"}} >			
					<Hidden mdDown>
							<Typography align="center" variant="h1" gutterBottom style={{fontSize: '3.5vw', color: '#303030'}}>
								<b>Entrepreneurs</b>
							</Typography>
						</Hidden>
						<Hidden mdUp>
							<Typography align="center" variant="h1" gutterBottom style={{fontSize: '40px', color: '#303030'}}>
								<b>Entrepreneurs</b>
							</Typography>
						</Hidden>
					</Item>
				</Grid>
				{/* <Grid item xs={12}>
				</Grid> */}
          		{entrepreneurs.map((contact,i) => <Grid key={i} item xs={12} sm={6} md={6} lg={6} xl={6}><EntrepreneurCard contact={contact}/></Grid>)}
      	</Grid>
    </Box>
	);
}