

import * as React from 'react';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box'

export default function OverflowCard() {

return(
<Box component="footer" sx={{ py: 3 }}>
<Typography level="body-xs" textAlign="center">
  © Valence Productions {new Date().getFullYear()}
</Typography>
</Box>
)
}