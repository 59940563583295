import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

export default function ContactCard(props) {
  return (
    <Card>
      <div>
        <Typography level="title-lg">{`${props.presenter.firstName} ${props.presenter.lastName}`}</Typography>
        <Typography level="body-sm">{props.presenter.title}</Typography>
      </div>
      <AspectRatio ratio = "1/1" minHeight="50px" maxHeight="300px" objectFit="contain">
        <img
          src= {props.presenter.picture}
          srcSet= {props.presenter.picture}
          loading="lazy"
          alt="presenter"
        />
      </AspectRatio>
      <CardContent >
        <div>
          <Typography level="body-xs">{props.presenter.company}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}