import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from "@mui/joy/Box";
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Chip from '@mui/joy/Chip';

	// {
	// 	firstName: "",
	// 	middleName: "",
	// 	lastName: "",
	// 	picture: "",
	// 	title: "",
	// 	company: "",
	// 	companyLogo: "",
	// 	email: "",
	// 	expertise: "",
	// 	linkedIn: "",
	// },


export default function ContactCard(props) {

  return (
    <Card style={{height: '100%'}}>
        <img
          src= {props.contact.picture ? props.contact.picture : props.contact.picture}
          srcSet= {props.contact.picture}
          loading="lazy"
          alt="picture"
          style={{borderRadius: '5px 5px 0px 0px'}}
        />
                <IconButton
        onClick={() => window.open(`${props.contact.linkedIn}`, '_blank')}
          aria-label="Like minimal photography"
          size="md"
          variant="solid"
          color="primary"
          sx={{
            position: 'absolute',
            zIndex: 2,
            borderRadius: '50%',
            width: '1vw',
            height: '1vw',
            right: 2,
            // bottom: 4,
            top: 2,

            transform: 'translateY(3%)',
          }}
        >
          <LinkedInIcon />
        </IconButton>
      <CardContent >

        <Typography level="h2">{`${props.contact.firstName} ${props.contact.lastName}`}</Typography>
         <Typography level="body-lg">{props.contact.title}</Typography>
          <Typography level="h4">{props.contact.company}</Typography>
          <br/>
        {/* <Typography level="body-sm">Expertise:</Typography>
        <br/>
        {props.contact.expertise.map((e,i)=> <Chip key={i} color='primary'>{e}</Chip>)}
        <br/> */}
      </CardContent>
      <CardOverflow variant="soft">
        <Divider inset="context" />
        <CardContent orientation="horizontal">
          <Typography 
            level="body-xs">{props.contact.email}</Typography>
        </CardContent>
        </CardOverflow>
    </Card>
  );
}