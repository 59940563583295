import * as React from "react";
import LobbyVideo from '../../../Assets/LobbyAssets/Light.mp4';
import LobbyImage from '../../../Assets/LobbyAssets/LightPicture.png';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import { color } from '../../../spec';
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden';
import { styled } from '@mui/joy/styles';
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(3),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	elevation: 24,
  }));

export default function Lobby() {
	const navigate = useNavigate()

	const handleNavigation = (link) => {
		navigate(link)
	}
	  
return(
	<>
	<Hidden smDown>
		<video 
		style={{width:'100%', position: 'fixed',}}
		autoPlay
		loop
		muted
		poster={LobbyImage}
	>
		<source style={{padding:'none', margin:'none',border:'none'}}
		src= {LobbyVideo}
		type="video/mp4"
		/>
		</video>
		<Grid container spacing={3} 
		alignItems="stretch"
			style={{
				// padding: 'none',
				maxWidth: '60%',
				position: 'fixed', 
				top: '15%',
				left: 'calc(180px + 18%)',
				// width: 'auto',
				// height: 'auto', 
				}}>
		<Grid item xs={12} style={{paddingBottom: '7%', borderColor: color, }} >
			<Typography align="center" variant="h1" level="h1" style={{fontSize: '5vw', color: 'white'}}>
				Innovation for Impact
			</Typography>
		</Grid>
		<Grid 
			item xs={6} md={6}  
			style={{padding: 'none', height: '100%'}} 
			onClick={()=>handleNavigation('/program')}>
				<Item style={{cursor: "pointer"}}>			
					<Typography align="center" variant="h1" gutterBottom style={{fontSize: '2vw', color: 'midnightblue'}}>
						<b>Program</b>
					</Typography>
					<Typography align="center" variant="h5" gutterBottom style={{fontSize: '1vw', color: 'midnightblue'}}>
						View all upcoming events
					</Typography>
				</Item>
		</Grid>
		<Grid item xs={6} md={6}  style={{padding: 'none'}}>
				<Item style={{cursor: "pointer"}}
				onClick={()=>handleNavigation('/contacts')}>			
					<Typography align="center" variant="h1" gutterBottom style={{fontSize: '2vw', color: 'midnightblue'}}>
						<b>Contacts</b>
					</Typography>
					<Typography align="center" variant="h5" gutterBottom style={{fontSize: '1vw', color: 'midnightblue'}}>
						Reach out to program managers
					</Typography>
				</Item>
		</Grid>
		<Grid item xs={6} md={6}  style={{padding: 'none'}}
		onClick={()=>handleNavigation('/mentors')}
		>
				<Item style={{cursor: "pointer"}}>			
					<Typography align="center" variant="h1" gutterBottom style={{fontSize: '2vw', color: 'midnightblue'}}>
						<b>Mentors</b>
					</Typography>
					<Typography align="center" variant="h5" gutterBottom style={{fontSize: '1vw', color: 'midnightblue'}}>
						Connect with Industry Experts
					</Typography>
				</Item>
		</Grid>

		<Grid item xs={6} md={6}  style={{padding: 'none'}}>
				<Item style={{cursor: "pointer"}}
				onClick={()=>handleNavigation('/entrepreneurs')}>			
					<Typography align="center" variant="h1" gutterBottom style={{fontSize: '2vw', color: 'midnightblue'}}>
						<b>Entrepreneurs</b>
					</Typography>
					<Typography align="center" variant="h5" gutterBottom style={{fontSize: '1vw', color: 'midnightblue'}}>
						View the 2024 Cohort
					</Typography>
				</Item>
		</Grid>
		</Grid>
	</Hidden>
	<Hidden mdUp>
		<Container>
		<Grid container spacing={3} 
		alignItems="stretch">
		<Grid item xs={12} >
		<br/>
			<Typography align="center" variant="h1" level="h1" style={{color}}>
				Innovation for Impact
			</Typography>
		</Grid>
		<Grid 
			item xs={12} md={6}
			style={{padding: 'none', height: '100%',}} 
			
			onClick={()=>handleNavigation('/agenda')}>
				<Item style={{cursor: "pointer"}}>			
					<Typography align="center" variant="h1" gutterBottom style={{color: 'midnightblue'}}>
						<b>Program</b>
					</Typography>
					<Typography align="center" variant="h5" gutterBottom style={{color: 'midnightblue'}}>
						View all upcoming events
					</Typography>
				</Item>
		</Grid>
		<Grid item xs={12} md={6} style={{padding: 'none'}}
		onClick={()=>handleNavigation('/sponsors')}
		>
				<Item style={{cursor: "pointer"}}>			
					<Typography align="center" variant="h1" gutterBottom style={{color: 'midnightblue'}}>
						<b>Contacts</b>
					</Typography>
					<Typography align="center" variant="h5" gutterBottom style={{color: 'midnightblue'}}>
					Reach out to program managers
					</Typography>
				</Item>
		</Grid>
		<Grid item xs={12} md={6} style={{padding: 'none'}}>
				<Item style={{cursor: "pointer"}}
				onClick={()=>handleNavigation('/contacts')}>			
					<Typography align="center" variant="h1" gutterBottom style={{color: 'midnightblue'}}>
						<b>Mentors</b>
					</Typography>
					<Typography align="center" variant="h5" gutterBottom style={{color: 'midnightblue'}}>
					Connect with Industry Experts
					</Typography>
				</Item>
		</Grid>
		<Grid item xs={12} md={6} style={{padding: 'none'}}>
				<Item style={{cursor: "pointer"}}
				onClick={()=>handleNavigation('/documents')}>			
					<Typography align="center" variant="h1" gutterBottom style={{color: 'midnightblue'}}>
						<b>Entrepreneurs</b>
					</Typography>
					<Typography align="center" variant="h5" gutterBottom style={{color: 'midnightblue'}}>
					View the 2024 Cohort
					</Typography>
				</Item>
		</Grid>
		</Grid>
		</Container>
	</Hidden>
	</>
)

}
