import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid'
import Box from '@mui/joy/Box'
import Container from '@mui/joy/Container'
import { color } from '../../spec';
import Production from '../../Assets/LandingPageAssets/Production.png'
import Microphone from '../../Assets/LandingPageAssets/Microphone.png'
import Computer from '../../Assets/LandingPageAssets/Computer.jpg'


const serviceContent = [
  {
    title: 'Event Management',
    subTitle: 'Work with our event planning experts to produce your event from start to finish or supplement your internal resources.',
    image: Production,
  },
  {
  title: 'Sponsorship Sales',
  subTitle: 'Leverage our network to add sponsors to your event. Attract thought leaders, fill out your content, and offset event expenses.',
  image: Microphone,
  }, {
  title: 'Virtual Environment',
  subTitle: 'Formalize your community with a virtual environment to connect groups from around the world around a  specific endeavor.',
  image: Computer,
  }
]

export default function OverflowCard() {
  return (
    <Container
    maxWidth='lg'
    style={{paddingBottom: '5vw'}}
    disableGutters
  >
    <Box sx={{ flexGrow: 1, p: 3 }} >
    <Grid container spacing={3}>
    <Grid item xs={12} style={{paddingBottom: '5vw'}}>
    <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 2.3636rem + 2.1818vw, 6rem)"
        align='center'
      >
       Preclinical Research Focused
      </Typography>
      <Typography
        level="h4"
        fontWeight="md"
        fontSize="clamp(2.875rem, 1.3636rem + 1.1818vw, 3rem)"
        align='center'
      >
       Industry Specific Services
      </Typography>
      </Grid>
      {serviceContent.map((card)=> {
        return(
<Grid item xs={12} sm = {6} md={4}>
    <Card variant="outlined" style={{height: '100%'}}>
      <Typography 
        style={{padding: '10px'}}
        fontSize="clamp(0.875rem, 1.3636rem + 0.4818vw, 6rem)"
        align='center' level="title-lg">{card.title}</Typography>
      <CardOverflow>
        <AspectRatio ratio="2">
          <img
            src={card.image}
            loading="lazy"
            alt="Show picture"
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent 
      style={{paddingTop: '15px', paddingBottom: '15px'}}
      >
        <Typography align='center' level="title-lg">{card.subTitle}</Typography>
      </CardContent>
      {/* <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>
        <Divider inset="context" />
        <CardContent orientation="horizontal">
          <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
            6.3k views
          </Typography>
          <Divider orientation="vertical" />
          <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
            1 hour ago
          </Typography>
        </CardContent>
      </CardOverflow> */}
    </Card>
    </Grid>

        )
      })}
        </Grid>
    </Box>
    </Container>
  );
}