import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify/utils';
import LandingPage from "./Routes/LandingPage/landingpage";
import DashboardLayout from "./Components/DashboardLayout";
import Enter from "./Routes/Authentication/enter";
import Agenda from "./Routes/Dashboard/Agenda/Agenda";
import Lobby from "./Routes/Dashboard/Lobby/Lobby";
import Contacts from "./Routes/Dashboard/Contacts/Contacts";
import Documents from "./Routes/Dashboard/Documents/Documents";
import Sponsors from "./Routes/Dashboard/Sponsors/Sponsors";
import Mentors from "./Routes/Dashboard/Mentors/Mentors";
import Entrepreneurs from "./Routes/Dashboard/Entrepreneurs/Entrepreneurs"
import ScrollToTop from "./Components/ScrollToTop";


export default function NewRouter() {

    const [authorized, setAuthorization] = useState(false);

    Hub.listen('auth', ({ payload }) => {
        switch (payload.event) {
          case 'signedIn':
            console.log('user have been signedIn successfully.');
            setAuthorization(true)
            break;
          case 'signedOut':
            console.log('user have been signedOut successfully.');
            setAuthorization(false)
            break;
          case 'tokenRefresh':
            console.log('auth tokens have been refreshed.');
            setAuthorization(true)
            break;
          case 'tokenRefresh_failure':
            console.log('failure while refreshing auth tokens.');
            setAuthorization(false)
            break;
          case 'signInWithRedirect':
            console.log('signInWithRedirect API has successfully been resolved.');
            setAuthorization(true)
            break;
          case 'signInWithRedirect_failure':
            setAuthorization(false)
            console.log('failure while trying to resolve signInWithRedirect API.');
            break;
          default:
            setAuthorization(false)
            console.log('unrecognized payload event');
        }
      });


    const { user } = useAuthenticator((context) => [context.user]);

    if(user && !authorized){
        setAuthorization(true)
    }


    const PrivateRoutes = () => {    
      return (
          authorized ? <DashboardLayout><Outlet/></DashboardLayout> : <Navigate to='/enter'/>
        )
      }

      if(authorized){
        return (
            <Router>
              <ScrollToTop />
                <Routes>
                  <Route element={<PrivateRoutes/>}>
                      <Route path='/' element={<Lobby/>} />
                      <Route path='/lobby' element={<Lobby/>} />
                      <Route path='/program' element={<Agenda/>} />
                      <Route path='/sponsors' element={<Sponsors/>} />
                      <Route path='/contacts' element={<Contacts/>} />
                      <Route path='/documents' element={<Documents/>} />
                      <Route path='/mentors' element={<Mentors/>} />
                      <Route path='/entrepreneurs' element={<Entrepreneurs/>} />
                      <Route path='*' element={<Lobby/>}/>
                  </Route>
                </Routes>
            </Router>
          );
      } else {
        return (
            <Router>
              <ScrollToTop />
                <Routes>
                  {/* <Route path='/enter' element={<Enter/>}/> */}
                  <Route path='*' element={<LandingPage/>}/>
                </Routes>
            </Router>
          );
      }


}