import React, { useState } from 'react';
import Box from "@mui/joy/Box";
import Typography from '@mui/joy/Typography';
import Grid from '@mui/material/Grid';
import ContactCard from "./ContactCard";
import { contacts } from '../../../spec';
import { styled } from '@mui/joy/styles';
import Paper from '@mui/material/Paper';
import Hidden from '@mui/material/Hidden';
import ContactsFilter from './ContactsFilter';
import { useEffect } from "react";
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import { color } from '../../../spec'

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(3),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));


export default function Contacts() {
	const [filterText, setFilterText] = useState();
	const [filtering, setFiltering] = useState(false);
	const [filteredContacts, setFilteredContacts] = useState(contacts);

	const filterMap = {
		firstName: 'First Name',
		middleName: 'Last Name',
		lastName: 'Last Name',
		title: 'Title',
		company: 'Company',
		expertise: 'Expertise',
	}

	if(filtering){
		let newFilteredContactsArray = filteredContacts

		setFilteredContacts(newFilteredContactsArray)
	}


	let filterComponent = (
			<Stack spacing={2} direction="row" alignItems="flex-start">
			  <>
			  <Select
				placeholder="Filter category"
				name="Filter"
				required
				sx={{ minWidth: 200 }}
			  >
				{Object.values(filterMap).map((e,i)=>{
					return <Option key={i} value={e}>{e}</Option>
				})}
			  </Select>
			  <Input type="text" name="text" onChange={(e)=>setFilterText(e.target.value)}/>
			  <Button
			  	style={{backgroundColor: color, color: 'white'}}
				type="submit" onClick={()=>setFiltering(!filtering)}>{filtering ? "Remove Filter" : "Filter"}</Button>
			  </>
			</Stack>
		)

	return (
    <Box sx={{ flexGrow: 1, p: 3 }} >
       <Grid container spacing={3} >
	   <Grid item xs={12}>
					<Item style={{height: "100%"}} >			
					<Hidden mdDown>
							<Typography align="center" variant="h1" gutterBottom style={{fontSize: '3.5vw', color: '#303030'}}>
								<b>Contacts</b>
							</Typography>
							<Typography align="center" variant="h5" gutterBottom style={{fontSize: '1.5vw', color: '#303030'}}>
								<b>Reach out to us with any questions about the program!</b>
							</Typography>
						</Hidden>
						<Hidden mdUp>
							<Typography align="center" variant="h1" gutterBottom style={{fontSize: '40px', color: '#303030'}}>
								<b>Contacts</b>
							</Typography>
							<Typography align="center" variant="h5" gutterBottom style={{fontSize: '20px', color: '#303030'}}>
								<b>Reach out to us with any questions about the program!</b>
							</Typography>
						</Hidden>
					</Item>
				</Grid>
				{/* <Grid item xs={12}>
				{filterComponent}
				</Grid> */}
          {filteredContacts.map((contact,i) => <Grid key={i} item xs={12} sm={6} md={6} lg={3} xl={2}><ContactCard contact={contact}/></Grid>)}
      </Grid>
    </Box>
	);
}