import documents from "./Data/documents.js";
import sponsors from "./Data/sponsors.js";
import contacts from "./Data/contacts.js";
import agenda from "./Data/agenda.js";
import mentors from "./Data/mentors.js";
import entrepreneurs from "./Data/entrepreneurs.js";

const companyName = "Valence Productions";
// const color = "#2746f8";
// const color = "#012169";
const color = "#017190";
//

const tag = "Vaence Productions";

const routes = {
	lobby: true,
	program: true,
	sponsors: true,
	contacts: true,
	mentors: true,
	entrepreneurs: true,
	resources: true,
};

export {
	routes,
	companyName,
	color,
	agenda,
	contacts,
	sponsors,
	documents,
	mentors,
	entrepreneurs,
	tag,
};

// really cool place to look at infite loop mp4s
// https://pixabay.com/videos/search/looping/
