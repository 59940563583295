import React
// , { useEffect } 
from 'react';
import { useNavigate } from "react-router-dom";
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/joy/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { 
    // getCurrentUser, 
    // fetchUserAttributes, 
    signOut } from 'aws-amplify/auth';
import { companyName, color, routes } from '../spec';

import LogoutIcon from '@mui/icons-material/Logout';
// import LobbyIcon from '@mui/icons-material/Home';
import LobbyIcon from '@mui/icons-material/Home';
import AgendaIcon from '@mui/icons-material/Event';
import SponsorsIcon from '@mui/icons-material/RocketLaunch';
import ContactsIcon from '@mui/icons-material/AccountBox';
import DocumentsIcon from '@mui/icons-material/Article';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'; // Mentors
import EngineeringIcon from '@mui/icons-material/Engineering';

const drawerWidth = 240;

function ResponsiveAppBar(props) {
  const navigate = useNavigate()  

  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const handleNavigation = (link) => {
    navigate(link)
  }

  return (
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} style={{backgroundColor:color}} >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <b style={{color:'white'}}>{companyName}</b>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List >
            {routes.lobby ?
            <ListItem key={'lobby'} onClick={()=>handleNavigation('/lobby')} disablePadding color={'black'}>
            <ListItemButton >
            <ListItemIcon>
                <LobbyIcon/>
                  </ListItemIcon>
                  <ListItemText primary={'Home'} />
              </ListItemButton>
            </ListItem>
            : null}
            {routes.program ?
            <ListItem key={'program'} onClick={()=>handleNavigation('/program')} disablePadding>
            <ListItemButton>
            <ListItemIcon>
                <AgendaIcon/>
                </ListItemIcon>
                  <ListItemText primary={'Program'} />
              </ListItemButton>
            </ListItem>
            : null }
            {routes.sponsors ?
            <ListItem key={'sponsors'} onClick={()=>handleNavigation('/sponsors')} disablePadding>
            <ListItemButton>
            <ListItemIcon>
                <SponsorsIcon/>
            </ListItemIcon>
            <ListItemText primary={'Sponsors'} />
              </ListItemButton>
            </ListItem>
            : null}
            {routes.contacts ?
            <ListItem key={'contacts'} onClick={()=>handleNavigation('/contacts')} disablePadding>
            <ListItemButton>
            <ListItemIcon>
                <ContactsIcon/>
                </ListItemIcon>
                <ListItemText primary={'Contacts'} />
              </ListItemButton>
            </ListItem>
            : null}
            {routes.mentors ?
            <ListItem key={'mentors'} onClick={()=>handleNavigation('/mentors')} disablePadding>
            <ListItemButton>
            <ListItemIcon>
                <SupervisorAccountIcon/>
                </ListItemIcon>
                <ListItemText primary={'Mentors'} />
              </ListItemButton>
            </ListItem>
            : null}
            {routes.entrepreneurs ?
            <ListItem key={'entrepreneurs'} onClick={()=>handleNavigation('/entrepreneurs')} disablePadding>
            <ListItemButton>
            <ListItemIcon>
                <EngineeringIcon/>
                </ListItemIcon>
                <ListItemText primary={'Entrepreneurs'} />
              </ListItemButton>
            </ListItem>
            : null}
            {routes.resources ?
            <ListItem key={'resources'} onClick={()=>handleNavigation('/documents')} disablePadding>
            <ListItemButton>
            <ListItemIcon>
                <DocumentsIcon/>
                </ListItemIcon>
                <ListItemText primary={'Resources'} />
              </ListItemButton>
            </ListItem>
            : null }
          </List>
          <Divider />
          <List>
            <ListItem key="Logout" onClick={()=>handleSignOut()} disablePadding>
                <ListItemButton>
                <ListItemIcon><LogoutIcon/>
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
                </ListItemButton>
              </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component="main" 
      // sx={{ flexGrow: 1, p: 3 }} 
      style={{ overflow: 'auto' }}>
        <Toolbar />
       {props.children}
      </Box>
    </Box>
  );
}
export default ResponsiveAppBar;