// import Farnaz from "../Assets/ContactAssets/Farnaz.jpeg";
import AgendaImageOne from "../Assets/AgendaAssets/DefaultAssetOne.mp4";
import AgendaImageTwo from "../Assets/AgendaAssets/DefaultAssetTwo.mp4";
import AgendaImageThree from "../Assets/AgendaAssets/DefaultAssetThree.mp4";
import AgendaImageFour from "../Assets/AgendaAssets/DefaultAssetFour.mp4";
import AgendaImageFive from "../Assets/AgendaAssets/DefaultAssetFive.mp4";
import AgendaImageSix from "../Assets/AgendaAssets/DefaultAssetSix.mp4";
import AgendaImageSeven from "../Assets/AgendaAssets/DefaultAssetSeven.mp4";
import ImageAssetOne from "../Assets/AgendaAssets/ImageAssetOne.png";
// import ImageAssetTwo from "../Assets/AgendaAssets/ImageAssetTwo.png";
import ImageAssetSeven from "../Assets/AgendaAssets/ImageAssetSeven.png";

// Speaker Pictures
import SteveLehmann from "../Assets/SpeakerAssets/SteveLehmann.png";
import DavidKaufman from "../Assets/SpeakerAssets/DavidKaufman.png";
import FrankDavid from "../Assets/SpeakerAssets/FrankDavid.png";
import JenFeng from "../Assets/SpeakerAssets/JenFeng.png";
import JenniferLee from "../Assets/SpeakerAssets/JenniferLee.png";
import MikeIacoviello from "../Assets/SpeakerAssets/MikeIacoviello.png";
import ACS from "../Assets/SpeakerAssets/ACSLogo.png";
import BE from "../Assets/BrightEdgeText.png";

const agenda = [
	{
		time: {
			startDate: "2024-03-13", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-03-13", // YYYY-MM-DD
			endTime: "13:30", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Finance",
			title:
				"Educational Session #1: Development Planning & Financing Strategy",
			description: "",
			picture: ImageAssetSeven,
			video: AgendaImageSeven,
			slides: "",
		},
		presenter: {
			firstName: "Steve",
			middleName: "",
			lastName: "Lehmann",
			picture: SteveLehmann,
			title: "Senior Director of Venture",
			company: "Portal Innovations",
		},
	},
	{
		time: {
			startDate: "2024-03-20", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-03-20", // YYYY-MM-DD
			endTime: "13:00", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Community",
			title: "Cohort Meet & Greet",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageOne,
			slides: "",
		},
		presenter: {
			firstName: "",
			middleName: "",
			lastName: "",
			picture: BE,
			title: "",
			company: "",
		},
	},
	{
		time: {
			startDate: "2024-04-17", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-04-17", // YYYY-MM-DD
			endTime: "13:30", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Business",
			title: "Educational Session #2: Markets & Competitive Landscape",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageThree,
			slides: "",
		},
		presenter: {
			firstName: "Mike",
			middleName: "",
			lastName: "Iacoviello",
			picture: MikeIacoviello,
			title: "Partner",
			company: "LifeSci Consulting",
		},
	},
	{
		time: {
			startDate: "2024-05-08", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-05-08", // YYYY-MM-DD
			endTime: "13:30", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Legal",
			title: "Educational Session #3: Corporate Law & IP",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageFour,
			slides: "",
		},
		presenter: {
			firstName: "Jen",
			middleName: "",
			lastName: "Feng",
			picture: JenFeng,
			title: "Partner",
			company: "Wilson Sonsini",
		},
	},
	{
		time: {
			startDate: "2024-05-22", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-05-22", // YYYY-MM-DD
			endTime: "13:30", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Venture Capital",
			title: "Educational Session #4: Investor Relations & Pitch Prep",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageFive,
			slides: "",
		},
		presenter: {
			firstName: "David",
			middleName: "",
			lastName: "Kaufman​",
			picture: DavidKaufman,
			title: "Partner",
			company: "Third Rock Ventures",
		},
	},
	{
		time: {
			startDate: "2024-06-11", // YYYY-MM-DD
			startTime: "13:00",
			endDate: "2024-06-11", // YYYY-MM-DD
			endTime: "14:30", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Clinical",
			title:
				"Educational Session #5: Regulatory Frameworks & Clinical Strategies",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageTwo,
			slides: "",
		},
		presenter: {
			firstName: "Jennifer",
			middleName: "",
			lastName: "Lee",
			picture: JenniferLee,
			title: "CEO",
			company: "JLC Life Sciences",
		},
	},
	{
		time: {
			startDate: "2024-06-25", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-06-25", // YYYY-MM-DD
			endTime: "13:30", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Clinical",
			title:
				"Introduction to the American Cancer Society: Patient Support & Discovery",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageOne,
		},
		presenter: {
			firstName: "",
			middleName: "",
			lastName: "",
			picture: ACS,
			title: "",
			company: "",
		},
	},
	{
		time: {
			startDate: "2024-07-17", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-07-17", // YYYY-MM-DD
			endTime: "13:00", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Community",
			title: "Cohort Meet & Greet",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageOne,
			slides: "",
		},
		presenter: {
			firstName: "",
			middleName: "",
			lastName: "",
			picture: BE,
			title: "",
			company: "",
		},
	},
	{
		time: {
			startDate: "2024-09-11", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-09-11", // YYYY-MM-DD
			endTime: "13:30", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Clinical",
			title: "Educational Session #6: Clinical Trials",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageSix,
			slides: "",
		},
		presenter: {
			firstName: "Frank",
			middleName: "",
			lastName: "David",
			picture: FrankDavid,
			title: "Founder & Managing Director",
			company: "Pharmagellan",
		},
	},
	{
		time: {
			startDate: "2024-09-18", // YYYY-MM-DD
			startTime: "12:00",
			endDate: "2024-09-18", // YYYY-MM-DD
			endTime: "13:00", // HH:MM
			timeZone: "EDT", // default GMT
		},
		access: {
			liveLink: "",
			archiveLink: "",
			disabled: false,
		},
		content: {
			topic: "Community",
			title: "Cohort Meet & Greet",
			description: "",
			picture: ImageAssetOne,
			video: AgendaImageOne,
			slides: "",
		},
		presenter: {
			firstName: "",
			middleName: "",
			lastName: "",
			picture: BE,
			title: "",
			company: "",
		},
	},
];

export default agenda;
