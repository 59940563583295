import Aumenta from "../Assets/EntrepreneurAssets/Aumenta.png";
import Cellens from "../Assets/EntrepreneurAssets/Cellens.png";
import Cloverleaf from "../Assets/EntrepreneurAssets/Cloverleaf.png";
import Kovina from "../Assets/EntrepreneurAssets/Kovina.png";
import Mission from "../Assets/EntrepreneurAssets/Mission.png";
import PearlSci from "../Assets/EntrepreneurAssets/PearlSci.png";
import Predicta from "../Assets/EntrepreneurAssets/Predicta.png";
import SyzOnc from "../Assets/EntrepreneurAssets/SyzOnc.png";
import TippingPoint from "../Assets/EntrepreneurAssets/TippingPoint.png";

const entrepreneurs = [
	{
		picture: Aumenta,
		text: "Dr. Andrew Koh is a physician, scientist, and an entrepreneur. Andrew was awarded an American Cancer Society Institutional Research Grant in 2011 that provided the foundation for his research and ultimately led to the launch of his company, Aumenta Biosciences, in 2021 to develop and advance gut microbiome-based therapeutics for enhancing anti-cancer immune responses.",
		website: "http://aumentabio.com/",
	},
	{
		picture: Cellens,
		text: "Jean Pham is the Co-Founder and CEO of Cellens, an early-stage cancer diagnostic company that leverages computational AI and cell mechanobiology to develop non-invasive cancer diagnostic tools. Cellens’ first product is a urine-based diagnostic test for detecting recurrence of bladder cancer.",
		website: "https://cellensinc.com/",
	},
	{
		picture: Cloverleaf,
		text: "Dr. Austin Draycott launched Cloverleaf Bio in 2023 following his doctoral research with ACS Research Scholar Wendy Gilbert at Yale University. Cloverleaf Bio is developing a new engineered “trojan horse” tRNA platform that allows for direct inhibition of up to 25 enzymes necessary for cancer growth with a single therapeutic molecule.",
		website: "https://cloverleafbio.com/",
	},
	{
		picture: Kovina,
		text: "Dr. Elliot Androphy received a Faculty Research Award and Research Grant from the American Cancer Society early in his career to support Human Papillomavirus (HPV) research. In 2020, Dr. Androphy co-founded Kovina Therapeutics to advance his research toward development of first-in-class antiviral therapeutics specifically designed to treat cancers and premalignant infections caused by HPV.",
		website: "https://www.kovina.com/",
	},
	{
		picture: Mission,
		text: "Mission-Driven Tech is a women’s health company in collaboration with Weill Cornell Medicine transforming gynecologic cancer care with modern technology.",
		website: "https://www.missiondriventech.com/",
	},
	{
		picture: PearlSci,
		text: "Dr. Traci Lyons is an American Cancer Society Mission Boost Grant awardee that has focused her research career on addressing aggressive cancer with effective targeted therapies. She co-founded Pearl Scientific to advance a novel targeted therapy that she developed in her laboratory at the University of Colorado to address Estrogen Receptor-positive and Triple Negative Breast Cancer.",
		website: "",
	},
	{
		picture: Predicta,
		text: "As a first-time CEO of Predicta Biosciences, Kate Caves is leading the development of a blood-based diagnostic platform built by ACS grantee Dr. Kenneth Anderson and Dr. Irene Ghobrial of the Dana-Farber Cancer Institute and Dr. Gad Getz of Mass-General Hospital. Predicta’s diagnostic platform combines genomic technology and immune profiling to improve clinical decision making and patient outcomes.",
		website: "",
	},
	{
		picture: SyzOnc,
		text: "Dr. Ashley Fuller is a former American Cancer Society postdoctoral fellow that is committed to entrepreneurship to extend her research to realize therapies for patients with rare cancers. Ashley’s company, SyzOnc, leverages artificial intelligence to expose untapped biological pathways in the tumor microenvironment and to identify novel and promising therapeutic leads.",
		website: "https://syzonc.com/",
	},
	{
		picture: TippingPoint,
		text: "Dr. Laura Hsieh is an American Cancer Society postdoctoral fellow turned founder and CEO of TippingPoint Biosciences, a drug development company dedicated to identifying novel treatments for diseases caused by defects in DNA packaging. TippingPoint Biosciences is initially focused on Diffuse Intrinsic Pontine Glioma (DIPG), with a long-term goal of providing new therapies for treatment of glioblastomas, pancreatic and lung cancers.",
		website: "https://tippingpointbiosciences.com/",
	},
];

export default entrepreneurs;
