import CROSelect from "../Assets/DocumentAssets/CROSelect.jpg";
import MedChem from "../Assets/DocumentAssets/MedChem.jpg";
import Partnership from "../Assets/DocumentAssets/Partnership.jpg";
import Template from "../Assets/DocumentAssets/Template.jpg";
import VCNetwork from "../Assets/DocumentAssets/VCNetwork.jpg";
import Writting from "../Assets/DocumentAssets/Writting.jpg";

const documents = [
	{
		title: "List of CROs",
		subTitle: "Integrated drug discovery and virtual biotechs",
		picture: CROSelect,
		fileType: "csv",
		fileLocation: "https:google.com",
		fileSize: "1.25 MB",
	},
	{
		title: "Writting an SBIR",
		subTitle: "A template for success",
		picture: Writting,
		fileType: "doc",
		fileLocation: "https:google.com",
		fileSize: "1.00 MB",
	},
	{
		title: "Partnership Plays",
		subTitle:
			"Connections for realizing a pharmaceutical strategic partnership",
		picture: Partnership,
		fileType: "pdf",
		fileLocation: "https:google.com",
		fileSize: "1.44 MB",
	},
	{
		title: "Hiring",
		subTitle: "Affiliated network of medchemists",
		picture: MedChem,
		fileType: "xlsx",
		fileLocation: "https:google.com",
		fileSize: "2.76 MB",
	},
	{
		title: "Pitch Examples",
		subTitle: "Templates for your VC pitch of funded companies",
		picture: Template,
		fileType: "ppt",
		fileLocation: "https:google.com",
		fileSize: "1.95 MB",
	},
	{
		title: "Boston Based VCs",
		subTitle: "A list of VCs to help jump start your follow on round",
		picture: VCNetwork,
		fileType: "csv",
		fileLocation: "https:google.com",
		fileSize: "3.20 MB",
	},
];

export default documents;
