// import Alice from "../Assets/ContactAssets/Alice.jpeg";
import JimA from "../Assets/MentorAssets/JimA.jpeg";
import Priya from "../Assets/MentorAssets/PriyaB.jpeg";
import RanjitB from "../Assets/MentorAssets/RanjitB.jpeg";
import BillBunker from "../Assets/MentorAssets/BillBunker.jpeg";
import JitC from "../Assets/MentorAssets/JitC.jpeg";
import SvenC from "../Assets/MentorAssets/SvenChrisian.png";
import JoeCollura from "../Assets/MentorAssets/JoeCollura.jpeg";
import ScottFisherman from "../Assets/MentorAssets/ScottFisherman.jpeg";
import ChrisG from "../Assets/MentorAssets/ChrisG.jpeg";
import JimGraham from "../Assets/MentorAssets/JimGraham.jpeg";
import MichaelI from "../Assets/MentorAssets/MichaelI.jpeg";
import Dominique from "../Assets/MentorAssets/Dominique.png";
import MicheleLee from "../Assets/MentorAssets/MicheleLee.jpeg";
import DawnMattoon from "../Assets/MentorAssets/DawnMattoon.jpeg";
import RachelMeyers from "../Assets/MentorAssets/RachelMeyers.jpeg";
import KevinMills from "../Assets/MentorAssets/KevinMills.png";
import MartaNew from "../Assets/MentorAssets/MartaNew.png";
import ChrisP from "../Assets/MentorAssets/ChrisP.jpeg";
import VadimShepel from "../Assets/MentorAssets/VadimShepel.jpeg";
import JaysonSterba from "../Assets/MentorAssets/JaysonSterba.jpeg";
import AmandaWagner from "../Assets/MentorAssets/AmandaWagner.jpeg";
import KateYen from "../Assets/MentorAssets/KateYen.jpeg";
import LeighZawel from "../Assets/MentorAssets/LeighZawel.jpeg";
import JohnHallinan from "../Assets/MentorAssets/JohnHallinan.jpeg";
import JenniferLee from "../Assets/MentorAssets/JenniferLee.jpeg";
import FrankDavid from "../Assets/MentorAssets/FrankDavid.jpeg";

const mentors = [
	{
		firstName: "Jim",
		middleName: "",
		lastName: "Audia",
		picture: JimA,
		title: "EVP Drug Discovery & Early Development",
		company: "FLARE Therapeutics",
		companyLogo: "",
		email: "audia.consulting1@gmail.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/jim-a-5993024/",
	},
	{
		firstName: "Priya",
		middleName: "",
		lastName: "Balachandran",
		picture: Priya,
		title: "Co-Founder",
		company: "Atzeyo Biosensors",
		companyLogo: "",
		email: "Balachp1@gmail.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/priyabalachandran/",
	},
	{
		firstName: "Ranjit",
		middleName: "",
		lastName: "Bindra",
		picture: RanjitB,
		title: "Co-Founder",
		company: "Modifi Bio",
		companyLogo: "",
		email: "Ranjit.Bindra@modifibio.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/ranjit-bindra-777b174/",
	},
	{
		firstName: "Bill",
		middleName: "",
		lastName: "Bunker",
		picture: BillBunker,
		title: "CEO",
		company: "Navigating Cancer",
		companyLogo: "",
		email: "bbunker@navigatingcancer.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/bunkerbill/",
	},
	{
		firstName: "Jit",
		middleName: "",
		lastName: "Chakravarty",
		picture: JitC,
		title: "CEO",
		company: "1cBio",
		companyLogo: "",
		email: "jit.chakravarty@1cbio.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/jichakravarty/",
	},
	{
		firstName: "Sven",
		middleName: "",
		lastName: "Christian",
		picture: SvenC,
		title: "Senior Director of Cancer Biology",
		company: "Bayer",
		companyLogo: "",
		email: "sven.christian@bayer.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/sven-christian-9aa66913/",
	},
	{
		firstName: "Joe",
		middleName: "",
		lastName: "Collura",
		picture: JoeCollura,
		title: "Director",
		company: "Portal Innovations",
		companyLogo: "",
		email: "Joe.Collura@portalinnovations.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/jvcollura/",
	},
	{
		firstName: "Scott",
		middleName: "",
		lastName: "Fishman",
		picture: ScottFisherman,
		title: "President and CEO",
		company: "Ethos LifeScience Advisors",
		companyLogo: "",
		email: "sfishman111@gmail.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/scottfishman111/",
	},
	{
		firstName: "Chris",
		middleName: "",
		lastName: "Garabedian",
		picture: ChrisG,
		title: "CEO",
		company: "Xontogeny",
		companyLogo: "",
		email: "Chris@xontogeny.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/chrisgarabedian/",
	},
	{
		firstName: "Jim",
		middleName: "",
		lastName: "Graham",
		picture: JimGraham,
		title: "Director of Investments",
		company: "Research Bridge Partners",
		companyLogo: "",
		email: "jgraham@researchbridgepartners.org",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/jimbgraham/",
	},
	{
		firstName: "Mike",
		middleName: "",
		lastName: "Iacoviello",
		picture: MichaelI,
		title: "Partner",
		company: "Life Sci Consulting",
		companyLogo: "",
		email: "mpiacoviello@gmail.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/michael-iacoviello-phd-35290317/",
	},
	{
		firstName: "Dominique",
		middleName: "",
		lastName: "Kendrick",
		picture: Dominique,
		title: "Co-Founder",
		company: "Atzeyo Biosensors",
		companyLogo: "",
		email: "dkendrick@everestgreenpartners.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/dominique-kendrick-rph-rac/",
	},
	{
		firstName: "Michele",
		middleName: "",
		lastName: "Lee",
		picture: MicheleLee,
		title: "Product and Commercial Strategy Consultant",
		company: "Consultant",
		companyLogo: "",
		email: "micheleleeconsult@gmail.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/michele-l/",
	},
	{
		firstName: "Dawn",
		middleName: "",
		lastName: "Mattoon",
		picture: DawnMattoon,
		title: "CEO",
		company: "Mercy BioAnalytics",
		companyLogo: "",
		email: "dawn@mercybio.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/dawn-mattoon-792511a/",
	},
	{
		firstName: "Rachel",
		middleName: "",
		lastName: "Meyers",
		picture: RachelMeyers,
		title: "Former CSO",
		company: "Faze Medicines",
		companyLogo: "",
		email: "rmeyers828@gmail.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/rachel-meyers-136a8/",
	},
	{
		firstName: "Kevin",
		middleName: "",
		lastName: "Mills",
		picture: KevinMills,
		title: "Co-Founder & CSO",
		company: "ZubiBio",
		companyLogo: "",
		email: "millskd@gmail.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/kevin-mills-20783914/",
	},
	{
		firstName: "Marta",
		middleName: "",
		lastName: "New",
		picture: MartaNew,
		title: "Founder & CEO",
		company: "Radyus Research",
		companyLogo: "",
		email: "mnew@radyusresearch.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/melarnew/",
	},
	{
		firstName: "Christopher",
		middleName: "",
		lastName: "Paschall",
		picture: ChrisP,
		title: "Director, Academic Liaison",
		company: "Bayer",
		companyLogo: "",
		email: "christopher.paschall@bayer.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/chrispaschall/",
	},
	{
		firstName: "Vadim",
		middleName: "",
		lastName: "Shepel",
		picture: VadimShepel,
		title: "Founder & CEO",
		company: "Bench2Bio",
		companyLogo: "",
		email: "vadim@bench2bio.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/vadim-shepel/",
	},
	{
		firstName: "Jayson",
		middleName: "",
		lastName: "Sterba",
		picture: JaysonSterba,
		title: "Director of AI and ESG Innovation",
		company: "Liberty Mutual",
		companyLogo: "",
		email: "jaysonsterba@gmail.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/jsterba/",
	},
	{
		firstName: "Amanda",
		middleName: "",
		lastName: "Wagner",
		picture: AmandaWagner,
		title: "CEO",
		company: "Immunitas Therapeutics",
		companyLogo: "",
		email: "awagner@immunitastx.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/amandathomaswagner/",
	},
	{
		firstName: "Kate",
		middleName: "",
		lastName: "Yen",
		picture: KateYen,
		title: "CEO",
		company: "Auron Therapeutics",
		companyLogo: "",
		email: "Kate.yen@aurontx.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/kate-yen-448b22108/",
	},
	{
		firstName: "Leigh",
		middleName: "",
		lastName: "Zawel",
		picture: LeighZawel,
		title: "CSO",
		company: "Pheon Therapeutics",
		companyLogo: "",
		email: "leigh.zawel@pheontx.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/leigh-zawel-7150907/",
	},
	{
		firstName: "John",
		middleName: "",
		lastName: "Hallinan",
		picture: JohnHallinan,
		title: "Chief Development Officer",
		company: "Danforth Advisors",
		companyLogo: "",
		email: "jhallinan@danforthadvisors.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/john-hallinan-744339/",
	},
	{
		firstName: "Jennifer",
		middleName: "",
		lastName: "Lee",
		picture: JenniferLee,
		title: "CEO",
		company: "JLC Life Sciences",
		companyLogo: "",
		email: "jennifer@jlclifesciences.com",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/jennifer-h-lee-/",
	},
	{
		firstName: "Frank",
		middleName: "",
		lastName: "David",
		picture: FrankDavid,
		title: "Founder & Managing Director",
		company: "Pharmagellen",
		companyLogo: "",
		email: "frank@pharmagellan.com ",
		expertise: [""],
		linkedIn: "https://www.linkedin.com/in/frank-s-david/",
	},
];

export default mentors;
