import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";

import { Authenticator, View } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import amplifyconfig from "./amplifyconfiguration.json";

import { Helmet } from "react-helmet";
import Router from "./router";
import CssBaseline from "@mui/joy/CssBaseline";

import favicon from "./Assets/favicon.ico";
import { companyName } from "./spec";

const helmet = (
	<Helmet>
		<meta charset="utf-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<meta name="theme-color" content="#000000" />
		<meta name="description" content="Eleveting Great Science" />
		<meta name="viewport" content="initial-scale=1, width=device-width" />
		<link rel="icon" href={favicon} />
		<link rel="apple-touch-icon" href={favicon} />
		<link rel="manifest" href={favicon} />
		<title>{companyName}</title>
	</Helmet>
);

Amplify.configure(amplifyconfig);

axios.defaults.baseURL = "http://localhost:5000";
axios.defaults.headers.common["Authorization"] = "AUTH TOKEN";
axios.defaults.headers.post["Content-Type"] = "application/json";

ReactDOM.createRoot(document.getElementById("root")).render(
	<Authenticator.Provider>
		<CssBaseline />
		<View>
			{helmet}
			{/* {Hubspot} */}
			<Router />
		</View>
	</Authenticator.Provider>
);
