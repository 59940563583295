import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Container from '@mui/joy/Container';
import { typographyClasses } from '@mui/joy/Typography';
import Hero from '../../Assets/NameAndLogo.png';
import { Link } from "react-router-dom";
import {color } from '../../spec';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Hidden from '@mui/material/Hidden';

export default function TwoSidedLayout({
  children,
  reversed,
}: React.PropsWithChildren<{ reversed?: boolean }>) {
  return (
    <Container>
    <Container
      sx={(theme) => ({
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: reversed ? 'column-reverse' : 'column',
        alignItems: 'center',
        py: 10,
        gap: 4,
        [theme.breakpoints.up(834)]: {
          flexDirection: 'row',
          gap: 6,
        },
        [theme.breakpoints.up(1199)]: {
          gap: 12,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
          maxWidth: '50ch',
          textAlign: 'center',
          flexShrink: 999,
          [theme.breakpoints.up(834)]: {
            minWidth: 420,
            alignItems: 'flex-start',
            textAlign: 'initial',
          },
          [`& .${typographyClasses.root}`]: {
            textWrap: 'balance',
          },
        })}
      >
      <Typography style= {{color}} fontSize="xl" fontWeight="lg">
      Communities for Preclinical Research
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(3.875rem, 2.3636rem + 3.1818vw, 8rem)"
      >
       Science Boldly Spoken
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        A full suite of event management services to forward scientific communication
      </Typography>
      <br/>
      {/* <Link textDecoration = 'none' to={"/enter"}>
      <Button size="lg" style={{backgroundColor:color}}
      endDecorator={<ArrowForward fontSize="xl" />}>
        View Demo
      </Button>
      </Link> */}
      </Box>
      <Hidden mdDown>
      <AspectRatio
        ratio={1}
        variant="outlined"
        maxHeight={500}
        height={'auto'}
        sx={(theme) => ({
          minWidth: 250,
          alignSelf: 'stretch',
          [theme.breakpoints.up(834)]: {
            alignSelf: 'initial',
            flexGrow: 1,
            '--AspectRatio-maxHeight': '500px',
            '--AspectRatio-minHeight': '200px',
          },
          borderRadius: 'sm',
          bgcolor: 'background.level2',
          flexBasis: '50%',
        })}
      >
        <img
          src={Hero}
          alt=""
        />
      </AspectRatio>
      </Hidden>
    </Container>
    </Container>
  );
}