import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import { color } from '../../../spec';
import SponsorsIcon from '@mui/icons-material/RocketLaunch';

// {
//     name: "PerkinElmer",
//     logo: PerkinElmer,
//     relatedPresentations: [],
//     relatedContacts: [],
//     expertise: [],
//     products: [],
//     services: [],
// },

export default function SponsorCard(props) {

  return (
    <Card style={{height: '100%'}}>
      <AspectRatio ratio="2">
        <img
          src= {props.sponsor.logo}
          srcSet= {props.sponsor.logo}
          loading="lazy"
          alt="picture"
          style={{borderRadius: '5px 5px 0px 0px'}}
        />
        </AspectRatio>
      <CardContent >
        {/* <Typography level="h2">{`${props.document.firstName} ${props.document.lastName}`}</Typography> */}
         {/* <Typography level="body-lg">{props.sponsor.name}</Typography> */}
          <Typography level="h4">{props.sponsor.description}</Typography>
          <br/>
        {/* <Typography level="body-sm">Expertise:</Typography> */}
        <br/>
        {/* {props.document.expertise.map((e)=> <Chip color='primary'>{e}</Chip>)} */}
        <br/>
        {/* <Typography level="body-sm">Expertise:</Typography>
        <br/>
        {props.sponsor.expertise.map((e)=> <Chip color='primary'>{e}</Chip>)}
        <br/> */}
      </CardContent>
      <CardOverflow variant="soft">
      <Button 
        style={{backgroundColor: color}}
        onClick={() => window.open(`${props.sponsor.website}`, '_blank')}
        startDecorator={<SponsorsIcon/>}>Go to Website</Button>
      <br/>
<Divider inset="context" />
<CardContent orientation="horizontal">
  {/* <Typography level="body-xs">File Size: {props.document.fileSize}</Typography>
  <Divider orientation="vertical" />
  <Typography level="body-xs">File Type: {props.document.fileType}</Typography> */}
</CardContent>
</CardOverflow>
    </Card>
  );
}